import React, { useEffect, useState } from 'react';
import { RowContainer } from '../global/containers';
import { SearchInputWithValue } from '../global/form';
import {
	Button,
	FormControl,
	MenuItem,
	Select,
	useMediaQuery,
} from '@mui/material';

export default function AssetMapForm({ setFilteredMarkersList, markersList }) {
	const [selectedAssetType, setSelectedAssetType] = useState('');
	const [searchedAssetType, setSearchedAssetType] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const isMobile = useMediaQuery('(max-width:1200px)');

	useEffect(() => {
		if (searchedAssetType || searchTerm) {
			// const searchTerm = event.target.value
			const lowerSearchTerm = searchTerm.toLowerCase();
			let filteredMarkers = markersList;

			if (searchedAssetType) {
				filteredMarkers = filteredMarkers.filter((marker) =>
					marker.asset.toLowerCase().includes(searchedAssetType.toLowerCase())
				);
			}

			if (searchTerm) {
				filteredMarkers = filteredMarkers.filter(
					(marker) =>
						marker.id.toString().toLowerCase().includes(lowerSearchTerm) ||
						marker.asset.toLowerCase().includes(lowerSearchTerm) ||
						marker.status.toString().toLowerCase().includes(lowerSearchTerm)
				);
			}

			setFilteredMarkersList(filteredMarkers);
		} else {
			setFilteredMarkersList(markersList);
		}
	}, [markersList, searchedAssetType, searchTerm, setFilteredMarkersList]);

	const clearSearch = () => {
		setSelectedAssetType(null);
		setSearchedAssetType(null);
		setSearchTerm('');
		setFilteredMarkersList(markersList);
	};

	return (
		<RowContainer
			alignItems="center"
			width={{ xs: '65vw', lg: '55vw' }}
			padding="0px 0px"
			marginBottom="0px"
			extraSX={{
				gap: 1,
			}}
		>
			<SearchInputWithValue
				placeholder="Search Assets..."
				type="text"
				required={true}
				width={{ xs: '100%', md: '40%' }}
				value={searchTerm}
				filter={(e) => {
					setSearchTerm(e.target.value);
				}}
			/>
			{isMobile && (
				<FormControl sx={{ width: '100%', marginBottom: '10px' }}>
					<Select
						labelId="asset-type-select-label"
						placeholder="Select Asset Type"
						id="asset-type-select"
						value={selectedAssetType}
						label="Asset Type"
						sx={{
							boxShadow: 'none',
							backgroundColor: '#EFEFEF',
							'.MuiOutlinedInput-notchedOutline': {
								border: 0,
							},
							'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
								{
									border: 0,
								},
							'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
								{
									border: 0,
								},
						}}
						onChange={(event) => {
							setSelectedAssetType(event.target.value);
							setSearchedAssetType(event.target.value);
						}}
					>
						<MenuItem value="All">All Assets</MenuItem>
						<MenuItem value="Bikes">Bikes (Electric)</MenuItem>
						<MenuItem value="Tuk">Tuk (Electric)</MenuItem>
						<MenuItem value="Trackers (Petrol Bike Trackers)">
							Trackers (Petrol Bike Trackers)
						</MenuItem>
						<MenuItem value="Batteries">Batteries</MenuItem>
						<MenuItem value="Charge Stations">Charge Stations</MenuItem>
						<MenuItem value="Swap Stations">Swap Stations</MenuItem>
					</Select>
				</FormControl>
			)}

			{!isMobile && (
				<FormControl sx={{ width: '40%', marginBottom: '10px' }}>
					<Select
						labelId="asset-type-select-label"
						placeholder="Select Asset Type"
						id="asset-type-select"
						value={selectedAssetType}
						label="Asset Type"
						sx={{
							boxShadow: 'none',
							backgroundColor: '#EFEFEF',
							'.MuiOutlinedInput-notchedOutline': {
								border: 0,
							},
							'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
								{
									border: 0,
								},
							'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
								{
									border: 0,
								},
						}}
						onChange={(event) => {
							setSelectedAssetType(event.target.value);
							setSearchedAssetType(event.target.value);
						}}
					>
						<MenuItem value="Bike">Bikes (Electric)</MenuItem>
						<MenuItem value="Tuk">Tuk (Electric)</MenuItem>
						<MenuItem value="Trackers">
							Trackers (Petrol Bike Trackers)
						</MenuItem>
						<MenuItem value="Battery">Batteries</MenuItem>
						<MenuItem value="Charging Station">Charge Stations</MenuItem>
						<MenuItem value="Swapping Station">Swap Stations</MenuItem>
					</Select>
				</FormControl>
			)}

			<Button
				onClick={clearSearch}
				color="secondary"
				sx={{ backgroundColor: '#EE4B2B', marginBottom: '10px' }}
			>
				Clear Search
			</Button>
		</RowContainer>
	);
}
