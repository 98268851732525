import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import backgroundImage from '../images/reset_page_background.jpg';
import enzi_logo from '../images/Enzi-logo-400px.png';
import { useRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormInput } from '../components/global/form';
import { CustomText } from '../components/global/text';
import { useNavigate } from 'react-router-dom';
import { ColumnContainer, RowContainer } from '../components/global/containers';
import enzi_theme from '../theme/theme';

function ResetPassword() {
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const navigate = useNavigate();

	const passwordRef = useRef();
	const passworConfirmdRef = useRef();

	const handleResetPassword = (data) => {
		let password = '';
		let passwordConfirm = '';
		if (passwordRef !== null && passwordRef.current !== null) {
			password = passwordRef.current.getState();
		}
		if (passworConfirmdRef !== null && passworConfirmdRef.current !== null) {
			passwordConfirm = passworConfirmdRef.current.getState();
		}

		console.log(password, passwordConfirm);
		navigate('/');
	};

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100vh',

				backgroundSize: 'cover',
				backgroundImage: `url(${backgroundImage})`,
				alignItems: 'center',
				justifyContent: 'flex-end',
				paddingRight: '3rem',
			}}
		>
			<ColumnContainer
				width="33.3%"
				height="auto"
				alignItems="flex-end"
				padding="10px 20px"
				extraSX={{
					backgroundColor: enzi_theme.palette.common.white,
					borderRadius: '10px',
				}}
			>
				<RowContainer width="100%" height="auto" justifyContent="flex-end">
					<img
						alt="logo"
						style={{ float: 'right' }}
						src={enzi_logo}
						width={'100px'}
						height={'auto'}
					/>
				</RowContainer>

				<CustomText
					text={'Reset your Password'}
					variant={'h1'}
					color={'secondary'}
					fontWeight={'800'}
				/>
				<CustomText
					text={`You’re password request was authenticated. Please enter your new password and confirm it to reset your password.`}
					variant={'body1'}
					color={'secondary'}
					width="100%"
				/>

				<ColumnContainer
					width="95%"
					alignItems="center"
					justifyContent="flex-start"
					marginBottom="0"
				>
					<FormInput
						ref={passwordRef}
						required={true}
						placeholder={'Enter New Password'}
						type={newPasswordVisible ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									sx={{ padding: 0 }}
									onClick={() => setNewPasswordVisible(!newPasswordVisible)}
								>
									{!newPasswordVisible ? (
										<Visibility color="#737373" />
									) : (
										<VisibilityOff color="#737373" />
									)}
								</IconButton>
							</InputAdornment>
						}
					/>
					<FormInput
						ref={passworConfirmdRef}
						required={true}
						placeholder={'Confirm New Password'}
						type={confirmPasswordVisible ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									sx={{ padding: 0 }}
									onClick={() =>
										setConfirmPasswordVisible(!confirmPasswordVisible)
									}
								>
									{!confirmPasswordVisible ? (
										<Visibility color="#737373" />
									) : (
										<VisibilityOff color="#737373" />
									)}
								</IconButton>
							</InputAdornment>
						}
					/>

					<Button
						variant="contained"
						size="large"
						sx={{ width: '300px', marginTop: 1 }}
						onClick={handleResetPassword}
					>
						Reset Password
					</Button>
				</ColumnContainer>
			</ColumnContainer>
		</Box>
	);
}

export default ResetPassword;
