import React, { useEffect, useRef, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../index.css';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { formatShortDateTime } from '../../util/util';

export default function Map({ markers, setSelectedMarker, setGetLocation }) {
	// TODO: clean this up
	let token =
		'pk.eyJ1IjoiZW56aXRlY2giLCJhIjoiY2x4dnF3ZXFtMGJ5djJsc2F4MDFqc3JtbCJ9.bcGBDUXkmNPaiKmPDHhKzg';
	mapboxgl.accessToken = token;
	const mapContainer = useRef(null);
	const map = useRef(null);
	const lng = 36.81;
	const lat = -1.28;
	const zoom = 10;

	const effectCalled = useRef(false);

	const directions = useMemo(
		() =>
			new MapboxDirections({
				accessToken: token,
				unit: 'metric',
				profile: 'mapbox/driving',
			}),
		[token]
	);

	const createPopup = (point) => {
		let baseUrl = process.env.REACT_APP_FRONTEND_URL;

		if (point.asset === 'Bike' || point.asset === 'Tuk') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Battery Charge: </b> ${point.charge_pct} % <br/>
				<b>Time: </b> ${formatShortDateTime(point.timestamp)} <br/>
				<b>Velocity: </b> ${point.velocity} km/h <br/>
				<b>Battery: </b> ${point.battery} <br/><br/>
				<a href="${baseUrl}/electric-vehicles/${
				point.searchId
			}" class="viewAssetLink">View Details</a>
				</div>`);
		} else if (point.asset === 'Battery') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Time: </b> ${formatShortDateTime(point.timestamp)} <br/>
				<b>Charge: </b> ${point.charge}% <br/>
				<b>Cycles: </b> ${point.cycles} <br/><br/>
				<a href="${baseUrl}/batteries/${
				point.searchId
			}" class="viewAssetLink">View Details</a>
				</div>`);
		} else if (point.asset === 'Swapping Station') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Working Hours: </b> ${point?.working_hours?.open + " - " + point?.working_hours?.close} <br/><br/>
				<a href="${baseUrl}/swap-station/${point.searchId}" class="viewAssetLink">View Details</a>
				</div>`);
		} else if (point.asset === 'Charging Station') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Working Hours: </b> ${point?.working_hours?.open + " - " + point?.working_hours?.close} <br/><br/>
				<a href="${baseUrl}/charge-station/${point.searchId}" class="viewAssetLink">View Details</a>
				</div>`);
		}
	};

	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: [lng, lat],
			zoom: zoom,
		});
		// map.current.addControl(directions, 'top-left');
		map.current.addControl(new mapboxgl.NavigationControl());
		map.current.on('boxzoomstart', (e) => {
			// event type: boxzoomstart
		});
	}, [directions, lat]);

	useEffect(() => {
		if (effectCalled.current) return;
		map.current.on('load', function () {
			directions.setOrigin([36.91916, -1.20328]);
			directions.setDestination([36.79606, -1.27673]);
			effectCalled.current = true;
		});
	}, [directions]);

	// adding markers
	useEffect(() => {
		markers?.forEach((point) => {
			const el = document.createElement('div');
			el.className = point.type;

			// create the marker popup
			const popup = createPopup(point);
			// add marker

			if (map.current !== null) {
				new mapboxgl.Marker({ element: el })
					.setLngLat([point.lon, point.lat])
					.setPopup(popup)
					.addTo(map.current);
			}
		});
	}, [map, markers, setGetLocation, setSelectedMarker]);

	return (
		<div
			style={{ border: '1px solid balck', width: '100%', height: '100%' }}
			ref={mapContainer}
			className="map-container"
		/>
	);
}
