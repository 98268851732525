import { useEffect, useState } from 'react';
import { ColumnContainer, ModalContainer } from '../global/containers';
import {
	handleErrorResponse,
	handleInfoResponse,
	isEmptyString,
} from '../../util/util';
import { FormSelect } from '../global/form';
import { assignBattery, listStations } from '../../services/batteryService';
import { useSelector } from 'react-redux';

const AssignBatteryModal = ({
	modalOpen = false,
	battery = null,
	toggleModal,
}) => {
	const [serialError, setSerialError] = useState(null);
	const [stationList, setStationList] = useState([]);
	const [selectedStation, setSelectedStation] = useState(null);
	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		listStations().then((response) => {
			setStationList(
				response.filter(
					(item) => !item.label.includes(battery?.battery_location)
				)
			);
		});
	}, [battery?.battery_location]);

	const handleSubmit = () => {
		if (isEmptyString(selectedStation)) {
			setSerialError('You must select a Station to assign the battery to');
			return;
		} else {
			setSerialError(null);
			const sendData = {
				bat_sn: battery?.bat_sn,
				station_id: selectedStation,
				user_id: user.id,
			};
			assignBattery(sendData)
				.then((response) => {
					handleInfoResponse(response?.data);
				})
				.catch((error) => handleErrorResponse(error))
				.finally(() => toggleModal());
		}
	};

	return (
		<ModalContainer
			title={`Assign Battery Asset #${battery?.bat_sn}`}
			modalOpen={modalOpen}
			height="auto"
			actionButtonText={'Assign Battery Asset'}
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
		>
			<ColumnContainer justifyContent="start" padding="0px">
				<FormSelect
					width={'80%'}
					required={true}
					label="Select a Station *"
					placeholder={'Select a Charge or Swap Station'}
					options={stationList}
					value={selectedStation}
					onChange={(e) => setSelectedStation(e.target.value)}
					error={serialError ? serialError : ''}
				/>
			</ColumnContainer>
		</ModalContainer>
	);
};

export default AssignBatteryModal;
