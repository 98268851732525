import React from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import InfoDetails from '../global/infoText';

export default function PaymentInfoCard({ riderInfo }) {
	return (
		<CardContainer
			title="Payment Information"
			width={{ xs: '100%', lg: '42%' }}
			height={{ xs: 'auto', lg: '95%' }}
			extraSX={{
				borderRadius: '10px',
			}}
		>
			<ColumnContainer padding="0px" alignItems="left">
				<InfoDetails
					marginBottom="0px"
					label={'Payment Method'}
					value={'M-pesa'}
				/>
				<InfoDetails
					marginBottom="0px"
					label={'Billing Account Number'}
					value={riderInfo?.phone_number}
				/>
				<InfoDetails
					marginBottom="0px"
					label={'Latest Payment'}
					value={riderInfo?.latest_payment}
				/>
			</ColumnContainer>
		</CardContainer>
	);
}
