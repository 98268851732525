import React from 'react';
import { ColumnContainer } from '../components/global/containers';
import TopBar from '../components/global/topBar';
import ManageRidersStatistics from '../components/cards/manageRidersStatistics';
import RiderListCard from '../components/cards/riderListCard';

export default function RiderListPage() {
	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			extraSX={{
				overflowY: 'auto',
			}}
		>
			<TopBar />
			<ManageRidersStatistics />
			<RiderListCard />
		</ColumnContainer>
	);
}
