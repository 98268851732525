import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import { ColumnContainer, RowContainer } from '../components/global/containers';
import { CustomText } from '../components/global/text';
import TrackersOverviewStatsCard from '../components/cards/trackersOverviewStatsCard';
import VehicleOverViewStatsCard from '../components/cards/vehicleOverViewStatsCard';
import RecentDispatchRequestscard from '../components/cards/recentDispatchRequestscard';
import BatteryHealthCard from '../components/cards/batteryHealthCard';
import BatteryStatusCard from '../components/cards/batteryStatusCard';
import RevenuePerformanceCard from '../components/cards/revenuePerformanceCard';
import StatisticsCard from '../components/cards/statisticsCard';
import SwapListCard from '../components/cards/swapListCard';
import ChargeListCard from '../components/cards/chargeListCard';
import TopBar from '../components/global/topBar';
import BikeOverViewStatus from '../components/cards/bikeOverViewStatus';
import { ChargingAndPowerUsageCard } from '../components/cards/chargingAndPowerUsageCard';
import FinancialReportDashboardCard from '../components/cards/financialReportDashboardCard';
import TopRidersCard from '../components/cards/topRidersCard';
import { useSelector } from 'react-redux';
export default function Dashboard() {
	const authState = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const handleRowClick = useCallback(
		(stationType) => (params) => {
			const route = stationType === 'swap' ? 'swap-station' : 'charge-station';
			navigate(`/${route}/${params.id}`);
		},
		[navigate]
	);

	const customSwapColumns = [
		{ field: 'name', headerName: 'Station Name', flex: 1 },
		{
			field: 'active_batteries',
			headerName: 'Active Batteries',
			flex: 1,
		},
		{ field: 'used_batteries', headerName: 'Used Batteries', flex: 1 },
		{ field: 'capacity', headerName: 'Capacity', flex: 1 },
		{ field: 'utilization', headerName: 'Utilization (%)', flex: 1 },
		{
			field: 'batteries_swapped',
			headerName: 'Batteries Swapped',
			flex: 1,
		},
	];

	const customChargeColumns = [
		{ field: 'name', headerName: 'Station Name', flex: 1 },
		{ field: 'active_batteries', headerName: 'Active Batteries', flex: 1 },
		{ field: 'idle_batteries', headerName: 'Idle Batteries', flex: 1 },
		{ field: 'capacity', headerName: 'Capacity', flex: 1 },
		{
			field: 'batteries_charged',
			headerName: 'Batteries Charged',
			flex: 1,
		},
		{
			field: 'power_consumed',
			headerName: 'Power Consumed (Kwh)',
			flex: 1,
		},
		{ field: 'utilization', headerName: 'Utilization (%)', flex: 1 },
	];

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			extraSX={{
				overflowY: 'auto',
			}}
		>
			<TopBar>
				<CustomText
					variant="h3"
					text={`Welcome back, ${authState?.user?.name}`}
					color="secondary"
					fontWeight="900"
					marginLeft="5%"
					width="500px"
					marginBottom="0px"
				/>
			</TopBar>
			<StatisticsCard />
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					width: '100%',
					height: { xs: 'auto', lg: 'auto' },
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					marginBottom: '10px',
					gap: '10px',
				}}
			>
				<TrackersOverviewStatsCard />
				<FinancialReportDashboardCard />
				<VehicleOverViewStatsCard />
			</RowContainer>
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					width: '100%',
					height: { xs: 'auto', lg: 'auto' },
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					marginBottom: '10px',
					gap: '10px',
				}}
			>
				<RecentDispatchRequestscard />
				<BatteryHealthCard />
			</RowContainer>
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					width: '100%',
					height: { xs: 'auto', lg: 'auto' },
					marginBottom: '10px',
					gap: '10px',
				}}
			>
				<BatteryStatusCard />
				<RevenuePerformanceCard />
			</RowContainer>
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					width: '100%',
					height: { xs: 'auto', lg: 'auto' },
					gap: '10px',
					marginBottom: '10px',
				}}
			></RowContainer>
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					width: '100%',
					height: { xs: 'auto', lg: '50vh' },
					marginBottom: { xs: '20px', lg: '10px' },
					gap: { xs: '20px', lg: '10px' },
				}}
			>
				<SwapListCard
					title="Swap Station Metrics"
					height={{ xs: 'auto', lg: '100%' }}
					width={{ xs: '100%', lg: '50%' }}
					showTimeRange={true}
					showAddButton={false}
					showSearch={false}
					showDownload={true}
					customColumns={customSwapColumns}
					onRowClick={handleRowClick('swap')}
				/>
				<ChargeListCard
					title="Charge Station Metrics"
					height={{ xs: 'auto', lg: '100%' }}
					width={{ xs: '100%', lg: '50%' }}
					showTimeRange={true}
					showAddButton={false}
					showSearch={false}
					showDownload={true}
					customColumns={customChargeColumns}
					onRowClick={handleRowClick('charge')}
				/>
			</RowContainer>
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					width: '100%',
					height: { xs: 'auto', lg: 'auto' },
					gap: { xs: '20px', lg: '10px' },
				}}
			>
				<ChargingAndPowerUsageCard />
			</RowContainer>
			<RowContainer
				justifyContent="space-between"
				marginBottom="0"
				extraSX={{
					width: '100%',
					height: { xs: 'auto', lg: '500px' },
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
					gap: { xs: '20px', lg: '10px' },
				}}
			>
				<BikeOverViewStatus />
				<TopRidersCard />
			</RowContainer>
		</ColumnContainer>
	);
}
