import React, { useState, useEffect } from 'react';
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from '../global/containers';
import {
	addElectricVehicles,
	editElectricVehicles,
} from '../../services/electricVehicle';
import {
	isEmptyString,
	isNullUndefined,
	issueSuccessMessage,
	removeWhiteSpaces,
} from '../../util/util';
import { FormInput, FormSelect } from '../global/form';
import { EV_TYPE_OPTIONS } from '../../constants/app.constants';
import { Box, useMediaQuery } from '@mui/material';

export default function AddEditVehicleModal({
	modalOpen = false,
	closeModal = () => {},
	vehicleAsset = null,
}) {
	const [isEdit] = useState(!isNullUndefined(vehicleAsset));
	const [evType, setEvType] = useState(vehicleAsset?.ev_type ?? '');
	const [modelNo, setModelNo] = useState(
		vehicleAsset?.motor_model_number ?? ''
	);
	const [motorSerial, setMotorSerial] = useState(vehicleAsset?.serial_number);
	const [registration, setRegistration] = useState(
		vehicleAsset?.registration_number ?? ''
	);
	const [chasisNo, setChasisNo] = useState(vehicleAsset?.chasis_number ?? '');
	const [ECISerialNo, setECISerialNo] = useState(
		vehicleAsset?.eci?.ECI_Sn ?? ''
	);
	const [eciTel, setEciTel] = useState(vehicleAsset?.eci?.tel);

	const [errors, setErrors] = useState({});
	const [formIsValid, setFormIsValid] = useState(true);
	const isMobile = useMediaQuery("(max-width:600px)");

	const validate = () => {
		const newErrors = {};

		if (isEmptyString(evType)) {
			newErrors.evType = 'EV Type is required.';
		}
		if (isEmptyString(modelNo)) {
			newErrors.modelNo = 'Model is required.';
		}

		//Seial Nmber (now CharField, max_length = 17)
		if (isEmptyString(motorSerial)) {
			newErrors.motorSerialNo = 'Motor Serial Number is required.';
		} else if (motorSerial.length > 17) {
			newErrors.motorSerialNo = 'Please input a valid serial number.';
		}
		if (isEmptyString(registration)) {
			newErrors.registration = 'Registration Number is required.';
		}
		if (isEmptyString(chasisNo)) {
			newErrors.chasis = 'Chasis Number is required.';
		}
		if (isEmptyString(ECISerialNo)) {
			newErrors.eci = 'ECI Serial Number is required.';
		}
		if (isNullUndefined(eciTel)) {
			newErrors.eciTel = 'ECI Tel Number is required.';
		}
		setErrors(newErrors);
		setFormIsValid(Object.keys(newErrors).length === 0);
		return Object.keys(newErrors).length === 0;
	};

	// // Run validation on every input change
	useEffect(() => {
		validate();
	}, [evType, modelNo, motorSerial, registration, chasisNo, ECISerialNo, eciTel]);

	const clearData = () => {
		setEvType('');
		setModelNo('');
		setMotorSerial('');
		setRegistration('');
		setChasisNo('');
		setECISerialNo('');
		setEciTel(null);
		setErrors({});
	};

	const handleSubmit = () => {
		if (validate()) {
			let sendData = {
				id: vehicleAsset?.id ?? null,
				eci: vehicleAsset?.eci?.id ?? null,
				ev_type: evType,
				Eci_sn: ECISerialNo,
				motor_model_number: modelNo,
				chasis_number: chasisNo,
				serial_number: motorSerial,
				registration_number: removeWhiteSpaces(registration.toUpperCase()),
				eciTel: eciTel,
			};
			if (isEdit) {
				editElectricVehicles(sendData, sendData.id)
					.then((data) => {
						issueSuccessMessage('Electric Vehicle Updated Successfully');
					})
					//TODO: Call set info here for internal edit
					.catch((err) => console.log(err));
			} else {
				addElectricVehicles(sendData)
					.then((resp) => {
						issueSuccessMessage('Electric Vehicle Added Successfully');
					})
					.catch((err) => console.log(err));
			}
			clearData();
			closeModal();
		}
	};

	return (
		<ModalContainer
			title={`${
				isEdit
					? `Edit ${vehicleAsset?.registration_number}`
					: `Add New Electric Vehicle`
			}`}
			modalOpen={modalOpen}
			height={isMobile ? "80vh" : "80vh"}
			width={isMobile ? "80vw" : "49vw"}
			actionButtonText={isEdit ? 'Save Vehicle' : 'Add Vehicle'}
			handleCloseModal={() => {
				clearData();
				closeModal();
			}}
			handleCancelClick={() => {
				clearData();
				closeModal();
			}}
			handleActionClick={handleSubmit}
			actionButtonDisabled={!formIsValid}
		>
			<Box sx={{ 
				height: '100%', 
				overflowY: 'auto', 
				width: '100%',
				padding: '5px'
			}}>
				<ColumnContainer justifyContent="start" padding="0px">
					<ColumnContainer alignItems="start">
						<RowContainer
							justifyContent="space-between"
							alignItems="center"
							marginBottom="0px"
							padding="10px 0px"
						>
							<FormSelect
								required={true}
							label="EV Asset *"
							width={'49%'}
							placeholder={'Select EV Asset'}
							value={evType}
							options={EV_TYPE_OPTIONS}
							error={errors?.evType}
							onChange={(e) => {
								setEvType(e.target.value);
							}}
						/>
						<FormInput
							required={true}
							label="Motor Model Number *"
							width={'49%'}
							placeholder={'Enter Model Number'}
							type="text"
							value={modelNo}
							onChange={(e) => {
								setModelNo(e.target.value);
							}}
							error={errors?.modelNo}
						/>
						</RowContainer>
					</ColumnContainer>
					<ColumnContainer alignItems="start">
						<RowContainer
							justifyContent="space-between"
							marginBottom="0px"
							padding="10px 0px"
						>
							<FormInput
								required={true}
								width={'49%'}
								label="Chasis Number *"
								placeholder={'Enter Chasis Number'}
								type="text"
								onChange={(e) => {
									setChasisNo(e.target.value.toUpperCase());
								}}
								value={chasisNo}
								error={errors?.chasis}
							/>
							<FormInput
								required={true}
								width={'49%'}
								label="Registration Number *"
								placeholder={'Enter Registration Number'}
								type="text"
								value={registration}
								onChange={(e) => {
									setRegistration(e.target.value);
								}}
								error={errors?.registration}
							/>
						</RowContainer>
					</ColumnContainer>
					<ColumnContainer alignItems="start">
						<RowContainer
							justifyContent="space-between"
							marginBottom="0px"
							padding="0px 0px"
						>
							<FormInput
								required={true}
								label="Motor Serial Number *"
								placeholder={'Enter Serial Number'}
								width={'49%'}
								type="text"
								maxLength={17}
								value={motorSerial}
								onChange={(e) => {
									setMotorSerial(e.target.value.toUpperCase());
								}}
								error={errors?.motorSerialNo}
							/>
							<FormInput
								required={true}
								width={'49%'}
								label="ECI Serial Number *"
								placeholder={'Enter ECI Serial Number'}
								type="text"
								value={ECISerialNo}
								onChange={(e) => {
									setECISerialNo(e.target.value);
								}}
								error={errors?.eci}
							/>
						</RowContainer>
						<RowContainer
							justifyContent="space-between"
							marginBottom="0px"
							padding="0px 0px"
						>
							<FormInput
								required={true}
								label="ECI Tel Number *"
								placeholder={'Enter ECI Tel Number'}
								width={'100%'}
								type="number"
								value={eciTel}
								onChange={(e) => {
									setEciTel(e.target.value);
								}}
								error={errors?.eciTel}
							/>
						</RowContainer>
					</ColumnContainer>
				</ColumnContainer>
			</Box>
		</ModalContainer>
	);
}
