import React, { useState, useCallback, useEffect } from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from '../global/customTable';
import BatteryDeploymentModal from '../modals/batteryDeploymentModal';
import { getBatteryDeployments } from '../../services/batteryService';
import { sortJSONArray } from '../../util/util';

export default function BatteryDeploymentHistoryCard({ bat_sn }) {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});

	const [isLoading, setIsLoading] = useState(false);
	const [batteryDeploymentList, setBatteryDeploymentList] = useState([]);

	const toggleModal = useCallback(
		async function () {
			setModalOpen(!modalOpen);
		},
		[modalOpen]
	);

	const batteryDeploymentsColumns = [
		{
			field: 'date',
			headerName: 'Issued On',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'ev',
			headerName: 'Reg No',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'startCharge',
			headerName: 'Issued Charge',
			minWidth: 100,
			flex: 1,
			valueFormatter: (value) => `${value} %`,
		},
		{
			field: 'endCharge',
			headerName: 'Received Charge',
			minWidth: 100,
			flex: 1,
			valueFormatter: (value) => `${value} %`,
		},
		{
			field: 'unitsConsumed',
			headerName: 'Units Used',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'duration',
			headerName: 'Duration',
			minWidth: 30,
			flex: 1,
		},
		{
			field: 'distance',
			headerName: 'Distance',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'manualReason',
			headerName: 'Swap Notes',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					menuOptions={[
						{
							label: 'View',
							onClickHandler: () => {
								setSelectedRow(params.row);
								setModalOpen(true);
							},
						},
					]}
				/>
			),
		},
	];

	useEffect(() => {
		setIsLoading(true);

		getBatteryDeployments(bat_sn)
			.then((response) => {
				setBatteryDeploymentList(response.sort(sortJSONArray('date', true)));
			})
			.finally(() => setIsLoading(false));
	}, [bat_sn]);

	return (
		<CardContainer
			title="Battery Deployments"
			width="100%"
			height="65vh"
			childrenContainerWidth="97%"
			extraSX={{ overflow: 'visible' }}
		>
			<ColumnContainer
				width={'100%'}
				height="100%"
				extraSX={{ margin: 0, padding: 0 }}
			>
				<CustomDataGrid
					height="65vh"
					width="100%"
					columns={batteryDeploymentsColumns}
					rows={batteryDeploymentList}
					isLoading={isLoading}
					isEmptyMessage="There are no battery deployments to show"
				/>
			</ColumnContainer>
			<BatteryDeploymentModal
				deployment={selectedRow}
				modalOpen={modalOpen}
				toggleModal={toggleModal}
			/>
		</CardContainer>
	);
}
