import React from 'react';
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from '../global/containers';
import InfoDetails from '../global/infoText';
import { formatDate } from '../../util/util';
import { Image } from '@mui/icons-material';
import { GET_SWAP_IMAGE } from '../../constants/url.constants';
import { Alert, AlertTitle, Button } from '@mui/material';
import { serviceAPIDownloadFile } from '../../services/service';

export default function RiderTransactionModal({
	modalOpen,
	toggleModal,
	data,
}) {

	async function fetchImage(swapId) {
		serviceAPIDownloadFile.get(GET_SWAP_IMAGE + parseInt(swapId, 10))
	}

	return (
		<ModalContainer
			title="Rider Transaction"
			modalOpen={modalOpen}
			height="auto"
			width={{ xs: '80%', md: '50%', xl: '30%' }}
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
				width="95%"
			>
				<ColumnContainer padding="0px" width="50" alignItems="left">
					<InfoDetails
						label={'Invoice Date'}
						value={
							data?.invoice?.date_created
								? formatDate(data?.invoice?.date_created)
								: '-'
						}
					/>
					<InfoDetails
						label={'Paired Station'}
						value={data?.issued_by_swapping_station_details?.name}
					/>
					<InfoDetails
						label={'Start Charge Level'}
						value={`${data?.issued_charge_percentage ?? '-'} %`}
					/>
					<InfoDetails
						label={'Total Energy Consumed'}
						value={`${data?.consumed_energy ?? '-'} %`}
					/>
				</ColumnContainer>
				<ColumnContainer width="50" alignItems="left">
					<InfoDetails
						label={'Battery SN'}
						value={data?.battery_details?.bat_sn}
					/>
					<InfoDetails
						label={'Returned Station'}
						value={data?.issued_by_swapping_station_details?.name}
					/>
					<InfoDetails
						label={'End Charge Level'}
						value={`${data?.received_charge_percentage ?? '-'} %`}
					/>
					<InfoDetails
						label={'Total Energy Bill'}
						value={`${data?.bill_amount ?? '-'} Ksh`}
					/>
				</ColumnContainer>
			</RowContainer>
			{
				data?.manual_swap_reason && (
					<RowContainer>
						<Alert severity="info" sx={{ width: "95%", margin: "20px auto" }}>
							<AlertTitle>Manual Swap</AlertTitle>
							This swap was performed manually. 
							The reason given was "{data?.manual_swap_reason}"
						</Alert>
					</RowContainer>
				)
			}
			{data?.image && (
				<Button
					variant="contained"
					color="secondary"
					endIcon={<Image />}
					sx={{ width: "300px", margin: "20px auto" }}
					onClick={() => fetchImage(data?.id)}
				>
					View Image
				</Button>
			)}
		</ModalContainer>
	);
}
