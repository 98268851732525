import React, { useEffect, useRef, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../index.css';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { formatShortDateTime } from '../../util/util';

export default function Map({
	markers,
	setSelectedMarker,
	setGetLocation,
	pathFollowed = [],
}) {
	// TODO: clean this up
	let token =
		'pk.eyJ1IjoiZW56aXRlY2giLCJhIjoiY2x4dnF3ZXFtMGJ5djJsc2F4MDFqc3JtbCJ9.bcGBDUXkmNPaiKmPDHhKzg';
	mapboxgl.accessToken = token;
	const mapContainer = useRef(null);
	const isLayerAdded = useRef(false);
	const map = useRef(null);
	const lng = 36.81;
	const lat = -1.28;
	const zoom = 10;

	const effectCalled = useRef(false);

	const directions = useMemo(
		() =>
			new MapboxDirections({
				accessToken: token,
				unit: 'metric',
				profile: 'mapbox/driving',
			}),
		[token]
	);

	const createPopup = (point) => {
		let baseUrl = process.env.REACT_APP_FRONTEND_URL;

		if (point.asset === 'Bike' || point.asset === 'Tuk') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Battery Charge: </b> ${point.charge_pct} % <br/>
				<b>Time: </b> ${formatShortDateTime(point.timestamp)} <br/>
				<b>Velocity: </b> ${point.velocity} km/h <br/>
				<b>Battery: </b> ${point.battery} <br/><br/>
				<a href="${baseUrl}/electric-vehicles/${
				point.searchId
			}" class="viewAssetLink">View Details</a>
				</div>`);
		} else if (point.asset === 'Battery') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Time: </b> ${formatShortDateTime(point.timestamp)} <br/>
				<b>Charge: </b> ${point.charge}% <br/>
				<b>Cycles: </b> ${point.cycles} <br/><br/>
				<a href="${baseUrl}/batteries/${
				point.searchId
			}" class="viewAssetLink">View Details</a>
				</div>`);
		} else if (point.asset === 'Swapping Station') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Working Hours: </b> ${
					point?.working_hours?.open + ' - ' + point?.working_hours?.close
				} <br/><br/>
				<a href="${baseUrl}/swap-station/${
				point.searchId
			}" class="viewAssetLink">View Details</a>
				</div>`);
		} else if (point.asset === 'Charging Station') {
			return new mapboxgl.Popup({ offset: 25, closeButton: false })
				.setHTML(`<div>
				<br />
				<b>${point.asset}:</b> ${point.id} <br/>
				<b>Status: </b> ${point.status} <br/>
				<b>Lat: </b> ${point.lat}° <br/>
				<b>Lon: </b> ${point.lon}° <br/>
				<b>Working Hours: </b> ${
					point?.working_hours?.open + ' - ' + point?.working_hours?.close
				} <br/><br/>
				<a href="${baseUrl}/charge-station/${
				point.searchId
			}" class="viewAssetLink">View Details</a>
				</div>`);
		}
	};

	const zoomToPath = (map, path) => {
		if (!map || !Array.isArray(path) || path.length === 0) return;
	
		const bounds = new mapboxgl.LngLatBounds();
	
		path.forEach(([lon, lat]) => {
			bounds.extend([lon, lat]);
		});
	
		map.fitBounds(bounds, {
			padding: { top: 20, bottom: 20, left: 20, right: 20 },
			maxZoom: 15, // Adjust maxZoom as needed
		});
	};

	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: pathFollowed.length > 0 ? pathFollowed[0] : [lng, lat],
			zoom: zoom,
		});
		if (pathFollowed.length > 0) {
			zoomToPath(map.current, pathFollowed);
		}
		if (pathFollowed.length > 0) {
			zoomToPath(map.current, pathFollowed);
		}
		// map.current.addControl(directions, 'top-left');
		map.current.addControl(new mapboxgl.NavigationControl());
		map.current.on('boxzoomstart', (e) => {
			// event type: boxzoomstart
		});
	}, [lng, lat, pathFollowed]);

	useEffect(() => {
		if (effectCalled.current) return;
		map.current.on('load', function () {
			directions.setOrigin([36.91916, -1.20328]);
			directions.setDestination([36.79606, -1.27673]);
			effectCalled.current = true;
		});
	}, [directions]);

	// adding markers
	useEffect(() => {
		markers?.forEach((point) => {
			const el = document.createElement('div');
			el.className = point.type;

			// create the marker popup
			const popup = createPopup(point);
			// add marker

			if (map.current !== null) {
				new mapboxgl.Marker({ element: el })
					.setLngLat([point.lon, point.lat])
					.setPopup(popup)
					.addTo(map.current);
			}
		});
	}, [map, markers, setGetLocation, setSelectedMarker]);
	useEffect(() => {
		if (!map.current || isLayerAdded.current) return; // Exit if map is null or layer is added

		map.current.on('load', () => {
			// Add source and layer only if it hasn't been added
			if (!isLayerAdded.current) {
				map.current.addSource('route', {
					type: 'geojson',
					data: {
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'LineString',
							coordinates: [...pathFollowed],
						},
					},
				});

				map.current.addLayer({
					id: 'route',
					type: 'line',
					source: 'route',
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': '#002051',
						'line-width': 6,
					},
				});

				isLayerAdded.current = true; // Mark the layer as added
			}

			if (pathFollowed.length > 0) {
				new mapboxgl.Marker().setLngLat(pathFollowed[0]).addTo(map.current);

				new mapboxgl.Marker()
					.setLngLat(pathFollowed[pathFollowed.length - 1])
					.addTo(map.current);
			}

			// Cleanup function
			return () => {
				if (map.current.getLayer('route')) {
					map.current.removeLayer('route');
				}
				if (map.current.getSource('route')) {
					map.current.removeSource('route');
				}
				isLayerAdded.current = false; // Reset the layer tracking ref
			};
		});
	}, [map, pathFollowed]);

	return (
		<div
			style={{ border: '1px solid balck', width: '100%', height: '100%' }}
			ref={mapContainer}
			className="map-container"
		/>
	);
}
