import React, { useCallback, useEffect, useState } from 'react';
import { CardContainer } from '../global/containers';
import { CustomDataGrid } from '../global/customTable';
import { topRiders } from '../../services/riderService';
import ActionableComponent from '../global/actionableComponent';

const TopRidersCard = () => {
	const [dataRows, setDataRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
    const [timeRange, setTimeRange] = useState("week");

    const chargingAndPowerUsageColumns = [
        {
            field: "rider",
            headerName: "Rider",
            minWidth: 50,
            flex: 0.75,
        },
        {
            field: "total_distance",
            headerName: "Distance covered",
            minWidth: 75,
            flex: 0.75,
        },
        {
            field: "total_time_taken",
            headerName: "Time taken",
            minWidth: 75,
            flex: 0.75,
        },
        {
            field: "swap_count",
            headerName: "Swap count",
            minWidth: 75,
            flex: 0.75,
        },
        {
            field: "co2_emmition_saved",
            headerName: "Co2 saved (kg)",
            minWidth: 75,
            flex: 0.75,
        }
    ]

    const getRiders = useCallback((timeRange = { timeRange: "week" }) => {
        setIsLoading(true);
        topRiders(timeRange)
            .then(res => {
				const list = res.map((value, index) => {
					return {
						id: index,
						total_distance: value.total_distance.toFixed(2),
						total_time_taken: value.total_time_taken,
						rider: value.rider ?? '-',
						swap_count: value.swap_count,
						co2_emmition_saved: value.co2_emmition_saved.toFixed(2),
					};
				});
				setDataRows(list);
			})
			.catch(() => {
				setDataRows([]);
			})
			.finally(() => {
				setIsLoading(false);
			});
    },[setDataRows])

    const handleTimeRangeChange = (event) => {
        const newTimeRange = event.target.value;
        getRiders({timeRange: newTimeRange});
        setTimeRange(newTimeRange);
    };

    useEffect(() => {
        getRiders();
    }, [getRiders]);

    return (
        <CardContainer
            title="Top Riders"
            width={{ xs: "100%", lg: "70%" }}
            // height={"50vh"}
            childrenContainerWidth={"100%"}
            childrenContainerPadding={0}
            extraSX={{
                justifyContent: "center",
            }}
            hasActionableComponent={true}
            actionableComponent={
                <ActionableComponent
                    showTimeRange={true}
                    handleTimeRangeChange={handleTimeRangeChange}
                    timeRange={timeRange}
                />
            }
        >
            <CustomDataGrid
                columns={chargingAndPowerUsageColumns}
                rows={dataRows}
                isLoading={isLoading}
                // containerRef={cardContainerRef}
                width="100%"
                height="100%"
            />
        </CardContainer>
    )
}


export default TopRidersCard;