import React, { useCallback, useEffect, useState } from 'react';
import {
	CardContainer,
	ColumnContainer,
	RowContainer,
} from '../components/global/containers';
import InfoDetails from '../components/global/infoText';
import { FloatingActionButton } from '../components/global/button';
import { Edit } from '@mui/icons-material';
import AddEditVehicleModal from '../components/modals/addEditVehicleModal';
import PairedBatteryInformationCard from '../components/cards/pairedBatteryInformationCard';
import VehicleAssetRegisteredRiderCard from '../components/cards/vehicleAssetRegisteredRiderCard';
import { useParams } from 'react-router-dom';
import { getElectricVehiclesInfo } from '../services/electricVehicle';
import TopBar from '../components/global/topBar';
import RecentVehicleTripsCard from '../components/cards/recentVehicleTripsCard';
import LoadingOverlay from '../components/overlays/loading';
import { formatCurrency } from '../util/util';

const ECI_STATES = {
	idd: 'idling',
	mv: 'moving',
	off: 'off',
};

export default function ElectricVehicleDetailPage() {
	const [isLoading, setIsLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const param = useParams();
	const [electricVehicleInfo, setElectricVehicleInfo] = useState();

	useEffect(() => {
		if (param.id && !modalOpen) {
			getElectricVehiclesInfo(param.id)
				.then((res) => {
					setElectricVehicleInfo(res);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [param.id, modalOpen]);

	const toggleModal = useCallback(() => {
		setModalOpen((prev) => !prev);
	}, []);
	if (isLoading) {
		return <LoadingOverlay height="100%" message={'Loading...'} />;
	} else {
		return (
			<ColumnContainer
				height="100%"
				marginBottom="0px"
				extraSX={{
					overflowY: 'auto',
				}}
				alignItems="flex-start"
			>
				<TopBar />
				<RowContainer
					justifyContent="space-between"
					height="37.5vh"
					padding="0px"
					extraSX={{
						gap: '10px',
						paddingBottom: '10px',

						flexWrap: { xs: 'wrap', lg: 'nowrap' },
					}}
				>
					<CardContainer
						title="Basic Information"
						width={{ xs: '100%', lg: '33%' }}
						height="100%"
						extraSX={{ borderRadius: '10px' }}
						childrenContainerWidth="95%"
					>
						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
							width="100%"
						>
							<InfoDetails
								width="max-content"
								label="Model number"
								value={electricVehicleInfo?.motor_model_number ?? '--'}
							/>
							<InfoDetails
								width="max-content"
								label="Reg Number"
								value={electricVehicleInfo?.registration_number}
								alignItems={{ xs: 'flex-start', lg: 'end' }}
							/>
						</RowContainer>
						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
							width="100%"
						>
							<InfoDetails
								label="Current Mileage"
								width="max-content"
								value={`${
									formatCurrency(electricVehicleInfo?.mileage) ?? '-'
								} KM`}
								alignItems="flex-start"
							/>
							<InfoDetails
								label="Current Status"
								width="max-content"
								value={ECI_STATES[electricVehicleInfo?.eci?.state]}
								alignItems={{ xs: 'flex-start', lg: 'end' }}
							/>
						</RowContainer>

						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
						>
							<InfoDetails
								width="max-content"
								label="Chasis number"
								value={electricVehicleInfo?.chasis_number ?? '-'}
								alignItems={{ xs: 'flex-start' }}
							/>
							<InfoDetails
								width="max-content"
								label="ECI SN"
								value={electricVehicleInfo?.eci?.ECI_Sn ?? '-'}
								alignItems={{ xs: 'flex-start' }}
							/>
						</RowContainer>
						<RowContainer
							justifyContent="right"
							padding="0px"
							marginBottom="0px"
						>
							<FloatingActionButton
								icon={<Edit color="secondary" />}
								onclick={toggleModal}
							/>
						</RowContainer>
						{modalOpen && (
							<AddEditVehicleModal
								closeModal={toggleModal}
								modalOpen={modalOpen}
								vehicleAsset={electricVehicleInfo}
							/>
						)}
					</CardContainer>
					<PairedBatteryInformationCard
						batteryInfo={electricVehicleInfo?.battery}
					/>
					<VehicleAssetRegisteredRiderCard
						ownerInfo={electricVehicleInfo?.owner}
					/>
				</RowContainer>
				<RowContainer
					justifyContent="center"
					height="70vh"
					padding="0px"
					extraSX={{
						gap: '10px',
						paddingTop: '10px',
						flexWrap: { xs: 'wrap', lg: 'nowrap' },
					}}
				>
					<RecentVehicleTripsCard />
				</RowContainer>
			</ColumnContainer>
		);
	}
}
