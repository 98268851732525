import React, { useCallback, useEffect, useState } from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from '../global/customTable';
import { useNavigate } from 'react-router-dom';
import {
	deleteRider,
	listRiders,
	getTripHistoryReport,
} from '../../services/riderService';
import DateRangeReportModal from '../modals/dateRangeReportModal';
import dayjs from 'dayjs';
import AddRiderModal from '../modals/addRiderModal';
import EditRiderModal from '../modals/editRiderModal';
import ConfirmationModal from '../modals/confirmationModal';
import { issueSuccessMessage, issueErrorMessage } from '../../util/util';
import ActionableComponent from '../global/actionableComponent';

const RiderListCard = () => {
	const [riderList, setRiderList] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [AddmodalOpen, setAddModalOpen] = useState(false);
	const [editmodalOpen, setEditmodalOpen] = useState(false);
	const [riderToEdit, setRiderToEdit] = useState({});
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [riderToDelete, setDeleteRider] = useState({});
	const [tripHistoryModalOpen, setTripHistoryModalOpen] = useState(false);
	const [selectedRiderId, setSelectedRiderId] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [isDownloading, setIsDownloading] = useState(false);

	const navigator = useNavigate();

	const riderListColumns = [
		{
			field: 'riderName',
			headerName: 'Rider Name',
			width: 100,
			flex: 1,
		},
		{
			field: 'vehicleRegNumber',
			headerName: 'Vehicle Reg. Number',
			minWidth: 120,
			flex: 0.5,
		},
		{
			field: 'phoneNumber',
			headerName: 'Phone Number',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'paymentMethods',
			headerName: 'Payment Method(s)',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'latestPayment',
			headerName: 'Latest Payment (Ksh)',
			minWidth: 100,
			flex: 1,
			valueFormatter: (value) => `${value} Ksh`,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			minWidth: 70,
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					height="auto"
					menuOptions={[
						{
							label: 'View',
							onClickHandler: () => navigator(`/riders/${params.row.id}`),
						},
						{
							label: 'Edit',
							onClickHandler: () => editRiderInfo(params.row),
						},
						{
							label: 'Delete',
							onClickHandler: () => handleDelete(params.row),
						},
						{
							label: 'Trips History',
							onClickHandler: () => handleTripHistoryClick(params.row.id),
						},
					]}
				/>
			),
		},
	];

	const toggleDeleteModal = useCallback(
		async function () {
			setDeleteModalOpen(!deleteModalOpen);
		},
		[deleteModalOpen]
	);

	const editRiderInfo = (data) => {
		setRiderToEdit(data);
		toggleModalEditModal();
	};

	const handleDelete = (data) => {
		setDeleteRider(data);
		toggleDeleteModal();
	};

	const riderDelete = () => {
		deleteRider(riderToDelete.id).then((res) => {
			setRiderList(riderList.filter((value) => value.id !== riderToDelete.id));
			setFilteredData(
				filteredData.filter((value) => value.id !== riderToDelete.id)
			);
			issueSuccessMessage('Rider deleted succesfully');
			toggleDeleteModal();
		});
	};

	const fetchRideList = useCallback(async function () {
		setIsLoading(true);
		listRiders()
			.then((res) => {
				const formatedData = res.map((rider) => {
					const riderName = `${rider?.first_name}  ${rider?.last_name}`;
					return {
						id: rider.id,
						riderName: riderName,
						vehicleRegNumber: rider.bike_reg,
						phoneNumber: rider.phone_number,
						paymentMethods: rider.payment_method,
						latestPayment: rider.latest_payment,
						...rider,
					};
				});
				setRiderList(formatedData);
				setFilteredData(formatedData);
			})
			.catch((err) => {
				setRiderList([]);
			})
			.finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		fetchRideList();
	}, [fetchRideList, AddmodalOpen, editmodalOpen]);

	const toggleAddModal = useCallback(
		async function () {
			setAddModalOpen(!AddmodalOpen);
		},
		[AddmodalOpen]
	);

	const toggleModalEditModal = useCallback(
		async function () {
			setEditmodalOpen(!editmodalOpen);
		},
		[editmodalOpen]
	);

	const filterData = (ev) => {
		const params = ev.target.value.toLocaleLowerCase();

		if (params === '') {
			return setFilteredData(riderList);
		}

		const filters = (rider) =>
			rider?.first_name.toLowerCase().includes(params) ||
			rider?.last_name.toLowerCase().includes(params) ||
			rider?.bike_reg.toLowerCase().includes(params);
		const filteredData = riderList.filter(filters);
		setFilteredData(filteredData);
	};

	const handleTripHistoryClick = (riderId) => {
		setSelectedRiderId(riderId);
		// Set default dates to last 3 months
		setStartDate(dayjs().subtract(3, 'month').startOf('day'));
		setEndDate(dayjs().endOf('day'));
		setTripHistoryModalOpen(true);
	};

	const handleTripHistoryModalClose = () => {
		setTripHistoryModalOpen(false);
		setSelectedRiderId(null);
		setStartDate(null);
		setEndDate(null);
	};

	const handleStartDateChange = (date) => {
		setStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
	};

	const handleTripHistoryDownload = useCallback(async () => {
		setIsDownloading(true);

		try {
			const formattedStartDate = startDate
				? dayjs(startDate).format("YYYY-MM-DD")
				: dayjs().subtract(3, 'month').startOf('day').format("YYYY-MM-DD");
			const formattedEndDate = endDate
				? dayjs(endDate).format("YYYY-MM-DD")
				: dayjs().endOf('day').format("YYYY-MM-DD");

			await getTripHistoryReport(
				selectedRiderId,
				formattedStartDate,
				formattedEndDate
			);

			issueSuccessMessage('Trip history report downloaded successfully');
			handleTripHistoryModalClose();
		} catch (error) {
			issueErrorMessage(
				error.message || 'Failed to generate trip history report'
			);
		} finally {
			setIsDownloading(false);
		}
	}, [selectedRiderId, startDate, endDate]);

	return (
		<CardContainer
			title="All Riders"
			hasActionableComponent={true}
			height="75vh"
			width="100%"
			childrenContainerWidth="98%"
			actionableComponent={
				<ActionableComponent
					showAddButton={true}
					showSearch={true}
					filter={filterData}
					toggleAddModal={toggleAddModal}
					searchPlaceholder="Search Riders"
				/>
			}
		>
			<ColumnContainer
				width={'100%'}
				height="100%"
				extraSX={{ margin: 0, padding: 0 }}
			>
				<CustomDataGrid
					isLoading={isLoading}
					columns={riderListColumns}
					rows={filteredData}
					height="65vh"
					width="100%"
					isEmptyMessage="There are no rider assets to show"
				/>
			</ColumnContainer>
			<AddRiderModal toggleModal={toggleAddModal} modalOpen={AddmodalOpen} />
			<EditRiderModal
				toggleModal={toggleModalEditModal}
				modalOpen={editmodalOpen}
				riderInfo={riderToEdit}
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={toggleDeleteModal}
				onConfirmation={riderDelete}
				itemType="Rider"
				itemName={riderToDelete?.first_name + ' ' + riderToDelete?.last_name}
				confirmType="delete"
			/>
			<DateRangeReportModal
				title="Download Trip History Report"
				modalOpen={tripHistoryModalOpen}
				actionButtonText={isDownloading ? 'Downloading...' : 'Download'}
				handleCloseModal={handleTripHistoryModalClose}
				handleCancelClick={handleTripHistoryModalClose}
				handleActionClick={handleTripHistoryDownload}
				handleStartDateChange={handleStartDateChange}
				handleEndDateChange={handleEndDateChange}
				startDate={startDate}
				endDate={endDate}
				startDateLabel="Start Date"
				endDateLabel="End Date"
				minDate={null}
				maxDate={dayjs().endOf('day')}
				isDateRequired={false}
				isActionDisabled={isDownloading}
			></DateRangeReportModal>
		</CardContainer>
	);
}

export default RiderListCard